import styled from 'styled-components'

export const Container = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const WarningText = styled.div`
  color: ${({ theme }) => theme.colors.semanticNegative};
  font-weight: 700;
  font-size: 0.75rem;
`
