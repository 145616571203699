import { FC } from 'react'
import { Icon } from '../Icon'
import { Container, IconContainer, WarningText } from './AuthFormError.style'

interface Props {
  error?: Error
}

const getErrorMessage = (error: Error) => {
  if (error.message === 'Unauthorized') {
    return 'Invalid token, the link in your email may have expired. Please contact support@netpurpose.com to request a new link.'
  }
  return error.message || error.toString()
}

export const AuthFormError: FC<Props> = ({ error }) => {
  if (!error) {
    return null
  }
  return (
    <Container data-testid="AuthFormError">
      <IconContainer>
        <Icon icon="Warning" alt="warning" color="semanticNegative" />
      </IconContainer>
      <WarningText>{getErrorMessage(error)}</WarningText>
    </Container>
  )
}
